import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './animate-in.scss'

class AnimateIn extends Component {
  state = { visible: false }

  ref = React.createRef()

  async componentDidMount() {
    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`)
    }

    this.observer = new IntersectionObserver(this.onIntersection, {
      threshold: 0.5,
    })
    this.observer.observe(this.ref.current)
  }

  onIntersection = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.5) {
        observer.unobserve(entry.target)
        this.setState({ visible: true })
      }
    })
  }

  render() {
    const classes = classNames('animate', this.props.type, {
      visible: this.state.visible,
    })

    const styles = {
      transitionDuration: `${this.props.duration}ms`,
      transitionDelay: `${this.props.delay}ms`,
    }

    return (
      <div ref={this.ref} className={classes} style={styles}>
        {this.props.children}
      </div>
    )
  }
}

AnimateIn.propTypes = {
  type: PropTypes.string,
  duration: PropTypes.number,
  delay: PropTypes.number,
}

AnimateIn.defaultProps = {
  duration: 400,
  delay: 0,
}

export default AnimateIn
