import React, { Component } from 'react'

const Context = React.createContext()

export const Consumer = Context.Consumer

export class Provider extends Component {
  state = this.props.value

  onLocaleChange = value => {
    if (this.state.locale !== value) {
      this.setState({ locale: value })

      const redirect = this.state.originalPath
        ? `/${value}${this.state.originalPath}`
        : `/${value}`

      window.location.href = redirect
    }
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          onLocaleChange: this.onLocaleChange,
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default Context
