import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Logo from './Logo'

import ScreenReader from '../ScreenReader'
import Navigation from './Navigation'

import './header.scss'

class Header extends Component {
  state = { menu: false }

  closeMenu = () => {
    this.setState({ menu: false }, this.toggleBodyClass)
  }

  toggleMenu = () => {
    this.setState(
      prevState => ({ menu: !prevState.menu }),
      this.toggleBodyClass
    )
  }

  toggleBodyClass = () => {
    if (this.state.menu) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
  }

  componentDidMount() {
    this.toggleBodyClass()
  }

  render() {
    return (
      <header className="site-header">
        <div className="container">
          <div className="row">
            <div className="logo-wrap">
              <Logo isHome={this.props.isHome} />
            </div>
            <div className="menu-link-wrap">
              <button
                className="menu-link"
                onClick={this.toggleMenu}
                aria-expanded={this.state.menu}
              >
                <ScreenReader>Toggle Menu</ScreenReader>
              </button>
            </div>
          </div>
        </div>

        <Navigation isOpen={this.state.menu} handleClose={this.closeMenu} />
      </header>
    )
  }
}

Header.propTypes = {
  isHome: PropTypes.bool,
}

export default Header
