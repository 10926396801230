import React, { Component } from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'

import { localeData, fallback } from './locales'
import { Provider } from './Context'

addLocaleData(localeData)

export default ComposedComponent => {
  class withIntl extends Component {
    constructor(props) {
      super(props)
      const { locale, languages, originalPath } = props.pageContext

      this.state = {
        locale,
        languages,
        originalPath,
      }
    }

    render() {
      const locale = this.state.locale || fallback
      const messages = require(`../translations/${locale}.json`)

      return (
        <Provider value={this.state}>
          <IntlProvider locale={locale} messages={messages}>
            <ComposedComponent {...this.props} />
          </IntlProvider>
        </Provider>
      )
    }
  }
  return withIntl
}
