import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

import { Consumer } from './Context'

const I18nLink = ({ to, children, ...rest }) => (
  <Consumer>
    {language => {
      const { locale } = language
      const toWithLang = locale ? `/${locale}${to}` : `${to}`

      return (
        <Link to={toWithLang} {...rest}>
          {children}
        </Link>
      )
    }}
  </Consumer>
)

I18nLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

I18nLink.contextTypes = {
  language: PropTypes.object,
}

export default I18nLink
