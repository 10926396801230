import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Cookies from 'universal-cookie'

import { Link } from 'i18n'

import { ReactComponent as CloseIcon } from './close-icon.svg'

import './cookieBanner.scss'

const cookies = new Cookies()

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = { closed: true }
  }

  componentDidMount() {
    if (!cookies.get('cookieAccept')) {
      this.setState({ closed: false })
    }
  }

  handleAccept = () => {
    cookies.set('cookieAccept', true, { path: '/' })
    this.setState({ closed: true })
  }

  render() {
    if (this.state.closed) return null

    const privacyLink = (
      <Link to="/privacy-policy">
        {this.props.intl.formatMessage({ id: 'privacy.policy' })}
      </Link>
    )

    return (
      <div className="cookie-banner">
        <div className="container">
          <FormattedMessage id="cookie.banner" values={{ privacyLink }} />

          <button
            className="close-link"
            type="button"
            onClick={this.handleAccept}
          >
            <CloseIcon />
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>
    )
  }
}

export default injectIntl(CookieBanner)
