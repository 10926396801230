import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'i18n'
import { injectIntl } from 'react-intl'

import { Consumer } from 'i18n/Context'

import './navigation.scss'

const Navigation = ({ isOpen, handleClose, intl }) => {
  const classes = classNames('site-navigation', { 'is-open': isOpen })

  return (
    <nav className={classes}>
      <div className="inner">
        <ul className="menu">
          <li>
            <Link to="/" onClick={handleClose}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/#project" onClick={handleClose}>
              {intl.formatMessage({ id: 'the.project' })}
            </Link>
          </li>
          <li>
            <Link to="/architects" onClick={handleClose}>
              {intl.formatMessage({ id: 'architects' })}
            </Link>
          </li>
          <li>
            <Link to="/location" onClick={handleClose}>
              {intl.formatMessage({ id: 'location' })}
            </Link>
          </li>
          <li>
            <Link to="/apartments" onClick={handleClose}>
              {intl.formatMessage({ id: 'apartments' })}
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleClose}>
              {intl.formatMessage({ id: 'contact' })}
            </Link>
          </li>
        </ul>

        <div className="country-toggle">
          <Consumer>
            {({ languages, onLocaleChange }) => {
              if (!languages) return null

              return (
                <ul>
                  {languages.map(({ value, text }) => (
                    <li key={`locale-${value}`}>
                      <button onClick={() => onLocaleChange(value)}>
                        {text}
                      </button>
                    </li>
                  ))}
                </ul>
              )
            }}
          </Consumer>
        </div>
        <div className="back">  
          <ul>
            <li>
              <a href="https://www.motown.be" onClick={handleClose}>
              {intl.formatMessage({ id: 'back' })}
              </a>
            </li>
          </ul>
        </div>
      </div> 
    </nav>
  )
}

Navigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default injectIntl(Navigation)
