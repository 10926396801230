import React from 'react'
import { injectIntl } from 'react-intl'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'

import ContentBlock from '../components/ContentBlock'
import Hero from '../components/Home/Hero'
import TheProject from '../components/Home/TheProject'
import Finishings from '../components/Home/Finishings'

const IndexPage = ({ intl }) => {
  return (
    <Layout isHome>
      <SEO titleTemplate={false} />

      <Hero />

      <ContentBlock
        id="project"
        title={intl.formatMessage({ id: 'the.project' })}
      >
        <TheProject />
      </ContentBlock>

      <ContentBlock
        id="finishings"
        title={intl.formatMessage({ id: 'finishings' })}
      >
        <Finishings />
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(injectIntl(IndexPage))
