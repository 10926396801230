import React from 'react'
import { Link } from 'i18n'
import { injectIntl } from 'react-intl'

import ScrollToTop from '../ScrollToTop'

import './footer.scss'

const Footer = ({ intl }) => {
  return (
    <footer className="site-footer" id="siteFooter">
      <div className="container">
        <div className="inner">
          <nav className="menu">
            <ul>
              <li>
                <Link to="/privacy-policy">
                  {intl.formatMessage({ id: 'privacy.policy' })}
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy">
                  {intl.formatMessage({ id: 'cookie' })}
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  {intl.formatMessage({ id: 'contact' })}
                </Link>
              </li>
            </ul>
          </nav>

          <div className="copyright">
            <small>
              &copy; {intl.formatMessage({ id: 'copyright' })} &ndash;{' '}
              {intl.formatMessage({ id: 'title' })}
            </small>
          </div>
        </div>
      </div>

      <ScrollToTop />
    </footer>
  )
}

export default injectIntl(Footer)
