import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import Footer from '../Footer'
import CookieBanner from './CookieBanner'

import '../../scss/app.scss'

const Layout = ({ children, isHome }) => {
  return (
    <div className="site">
      <Header isHome={isHome} />
      <main className="site-content">{children}</main>
      <Footer />
      <CookieBanner />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
