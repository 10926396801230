import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AnimateIn from '../AnimateIn'

import './content-block.scss'

const ContentBlock = ({ children, title, className, id }) => {
  const classes = classNames('content-block', className, {
    'has-title': !!title,
  })
  return (
    <section className={classes} id={id}>
      {title && (
        <AnimateIn type="fadeLeft" delay={200}>
          <h2 className="title">{title}</h2>
        </AnimateIn>
      )}
      <div className="content-block-inner">{children}</div>
    </section>
  )
}

ContentBlock.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default ContentBlock
