import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'i18n'
import Parallax from 'react-rellax'
import { FormattedMessage, injectIntl } from 'react-intl'

import './hero.scss'

const Hero = ({ intl }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image: file(relativePath: { eq: "home-hero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <section className="home-hero">
          <div className="container">
            <header className="heading">
              <h1>
                {intl.formatMessage({ id: 'home.title' })}{' '}
                <FormattedMessage id="home.subtitle" />
              </h1>

              <Link to="/#project" className="project-link">
                {intl.formatMessage({ id: 'home.hero.link' })}
              </Link>
            </header>
          </div>

          <div className="content">
            <Parallax speed={-1} className="circle" />

            <div className="image">
              <Img fluid={data.image.childImageSharp.fluid} />
            </div>

            <blockquote>
              <p>{intl.formatMessage({ id: 'home.quote' })}</p>
              <footer>
                &ndash; {intl.formatMessage({ id: 'home.quote.author' })}
              </footer>
            </blockquote>
          </div>
        </section>
      )}
    />
  )
}

export default injectIntl(Hero)
