import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'i18n'
import { ReactComponent as SiteLogo } from './site-logo.svg'
import { ReactComponent as SiteLogoHome } from './site-logo-home.svg'
import ScreenReader from '../../ScreenReader'

import './logo.scss'

const Logo = ({ isHome }) => {
  if (isHome) {
    return (
      <a
        className="logo"
        href="https://motown.be"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SiteLogoHome />
        <ScreenReader>Motown Development</ScreenReader>
      </a>
    )
  }

  return (
    <Link className="logo" to="/">
      <SiteLogo />
      <ScreenReader>Motown Parc</ScreenReader>
    </Link>
  )
}

Logo.propTypes = {
  isHome: PropTypes.bool,
}

export default Logo
