import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Parallax from 'react-rellax'
import { injectIntl } from 'react-intl'

import Slider from '../../Slider'

import './the-project.scss'

const TheProject = ({ intl }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { relativeDirectory: { eq: "home-project-slider" } }
          ) {
            edges {
              node {
                id
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="the-project-section">
            <div className="container">
              <div className="desktop-only">
                <div className="text-wrap">
                  <blockquote>
                    <p>{intl.formatMessage({ id: 'home.quote' })}</p>
                    <footer>
                      &ndash; {intl.formatMessage({ id: 'home.quote.author' })}
                    </footer>
                  </blockquote>
                </div>
              </div>

              <div className="content-wrap">
                <div className="content-slider">
                  {data.images && <Slider slides={data.images.edges} />}
                </div>

                <div className="content-text">
                  <div className="text-wrap">
                    <h3 className="text-peach">
                      {intl.formatMessage({ id: 'home.project.title' })}
                    </h3>

                    <div className="read-more">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'home.project.paragraph.1',
                          }),
                        }}
                      />

                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'home.project.paragraph.2',
                          }),
                        }}
                      />

                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'home.project.paragraph.3',
                          }),
                        }}
                      />

                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'home.project.paragraph.4',
                          }),
                        }}
                      />

                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'home.project.paragraph.5',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Parallax className="circle" />
            </div>
          </section>
        )
      }}
    />
  )
}

export default injectIntl(TheProject)
