import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlickSlider from 'react-slick'
import uuidv4 from 'uuid/v4'
import Img from 'gatsby-image'

import 'slick-carousel/slick/slick.css'
import './slider.scss'

const Slider = ({ slides, speed, autoplaySpeed }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed,
  }

  return (
    <SlickSlider
      {...settings}
      className={classNames('image-slider', `slides-${slides.length}`)}
    >
      {slides.map(image => (
        <div key={uuidv4()} className="slide">
          {image.hasOwnProperty('node') ? (
            <Img fluid={image.node.childImageSharp.fluid} />
          ) : (
            <img src={image} alt="" />
          )}
        </div>
      ))}
    </SlickSlider>
  )
}

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
  speed: PropTypes.number,
  autoplaySpeed: PropTypes.number,
}

Slider.defaultProps = {
  speed: 600, // milliseconds
  autoplaySpeed: 4000, // milliseconds
}

export default Slider
