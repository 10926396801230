import React, { Component } from 'react'
import ScreenReader from '../ScreenReader'

import './scroll-to-top.scss'

class ScrollToTop extends Component {
  state = { visible: false }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    this.setState({ visible: scrollTop > 500 })
  }

  toTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  render() {
    if (!this.state.visible) return false

    return (
      <button className="scroll-top-link" onClick={this.toTop}>
        <ScreenReader>Back to Top</ScreenReader>
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.83 24.286H11.17c-.956 0-1.438-1.161-.76-1.83l8.831-8.777c.42-.42 1.09-.42 1.509 0l8.83 8.776c.688.67.206 1.83-.75 1.83zm11.17-20v31.428A4.287 4.287 0 0 1 35.714 40H4.286A4.287 4.287 0 0 1 0 35.714V4.286A4.287 4.287 0 0 1 4.286 0h31.428A4.287 4.287 0 0 1 40 4.286zm-4.286 30.893V4.82a.537.537 0 0 0-.535-.535H4.82a.537.537 0 0 0-.535.535V35.18c0 .294.24.535.535.535H35.18a.537.537 0 0 0 .535-.535z"
            fill="#C4C4C4"
          />
        </svg>
      </button>
    )
  }
}

export default ScrollToTop
