import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './screen-reader.scss'

const ScreenReader = props => {
  const classes = classNames('sr-only', {
    'sr-only-focusable': props.focus,
  })

  return <span className={classes}>{props.children}</span>
}

ScreenReader.propTypes = {
  focus: PropTypes.bool,
  children: PropTypes.node,
}

export default ScreenReader
